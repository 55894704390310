import React, { Component } from "react";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { db } from "../firebase/firebase";

class AddReviewForm extends Component {
  state = {
    name: "",
    rating: 0,
    message: "",
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  saveToDB = () => {
    const review = {
      name: this.state.name,
      rating: this.state.rating,
      reviews: [
        {
          who: "MAC_Adress_1",
          message: this.state.message,
          picture: [
            { picture: "url://to_fire_store_db" },
            { picture: "url://to_fire_store_db" },
          ],
        },
      ],
    };

    var subjectsRef = db.ref("subjects");
    var newReviewRef = subjectsRef.push();
    newReviewRef.set(review);
  };

  render() {
    return (
      <form onSubmit={this.saveToDB}>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Rating
            name="simple-controlled"
            value={this.state.rating}
            onChange={(event, newValue) => {
              this.setState({ rating: newValue });
            }}
          />
        </Box>
        <div className="form-group">
          <div class="form-group">
            <label for="formGroupExampleInput">Name</label>
            <input
              value={this.state.name}
              onChange={this.handleNameChange}
              type="text"
              class="form-control"
              id="formGroupExampleInput"
              placeholder="mollie.com"
            />
          </div>
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className="form-group">
          <label for="exampleFormControlTextarea1">Example textarea</label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
        <label for="formFileMultiple" className="form-label">
          Multiple files input example
        </label>
        <input
          className="form-control"
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id="formFileMultiple"
          multiple
        />
        <br />
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    );
  }
}

export default AddReviewForm;
